import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/usr/src/app/www/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/page-layout.js";
import { Link as GatsbyLink } from 'gatsby';
import { mix } from '@theme-ui/color';
import { PostCard } from '../components/post-card';
import { ProfileInfo } from '../components/profile-info';
import { NewsletterForm } from '../components/newsletter-form';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const Divider = makeShortcode("Divider");
const Link = makeShortcode("Link");
const Grid = makeShortcode("Grid");
const Box = makeShortcode("Box");
const SourceTags = makeShortcode("SourceTags");
const Badge = makeShortcode("Badge");
const SourceList = makeShortcode("SourceList");
const Flex = makeShortcode("Flex");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Heading as="h1" variant="styles.h1" mdxType="Heading">
  "Tom Larcher"
    </Heading>
    <Text as="h4" variant="styles.h4" mdxType="Text">
  "Software Development Manager @ Forcite"
    </Text>
    <Divider mdxType="Divider" />
    <Divider mdxType="Divider" />
    <Divider mdxType="Divider" />
    <h2>{`intro`}</h2>
    <p>{`The name's Tom. I like to explore and create.`}</p>
    <p>{`I'm a highly motivated citizen of the digital world, developing software for a living. My typical day is comprised of a large serving of both parenting and building software solutions, with the occasional (very welcomed) dash of game development, motorcycling, and other pursuits.`}</p>
    <blockquote>
      <p parentName="blockquote"><em parentName="p">{`When you don't create things, you become defined by your tastes rather than ability. Your tastes only narrow `}{`&`}{` exclude people.`}</em>{` `}<strong parentName="p"><em parentName="strong">{`So create.`}</em></strong>{` _why`}</p>
    </blockquote>
    <Divider mdxType="Divider" />
    <Divider mdxType="Divider" />
    <h2>{`current projects`}</h2>
    <Text mdxType="Text">
  <Link href="https://tomlarcher.com/posts/" variant="styles.a" rel="noopener" mdxType="Link">
    notes
  </Link> (frequently writing, sporadically publishing)
    </Text>
    <Divider mdxType="Divider" />
    <Text mdxType="Text">
  <Link href="https://www.findyourexit.com" target="_blank" variant="styles.a" rel="noopener" mdxType="Link">
    motorcycle escapades
  </Link> (actively riding, on a writing hiatus)
    </Text>
    <Divider mdxType="Divider" />
    <Text mdxType="Text">
  <Link href="https://www.forcitehelmets.com/" target="_blank" variant="styles.a" rel="noopener" mdxType="Link">
    my day job
  </Link> (developing a world-first smart motorcycle helmet!)
    </Text>
    <Divider mdxType="Divider" />
    <Text mdxType="Text"><strike>
  <Link href="https://wrenchriderepeat.com" target="_blank" variant="styles.a" rel="noopener" mdxType="Link">
    side hustle
  </Link> (a motorcycling-inspired online shop)</strike> (on hiatus)
    </Text>
    <Divider mdxType="Divider" />
    <Divider mdxType="Divider" />
    <Divider mdxType="Divider" />
    <Grid sx={{
      gridTemplateColumns: ['1fr', '1fr 1fr']
    }} mdxType="Grid">
      <Box mdxType="Box">
        <h2>{`contact`}</h2>
        <Text mdxType="Text">Email </Text>
        <Link href="mailto:tom.larcher@gmail.com" target="_blank" variant="styles.a" rel="noopener" mdxType="Link">
  tom.larcher@gmail.com
        </Link>
        <Divider mdxType="Divider" />
        <Text mdxType="Text">Twitter </Text>
        <Link href="https://twitter.com/findyourexit" target="_blank" variant="styles.a" rel="noopener" mdxType="Link">
  https://twitter.com/findyourexit
        </Link>
        <Divider mdxType="Divider" />
        <Text mdxType="Text">GitHub </Text>
        <Link href="https://github.com/findyourexit" target="_blank" variant="styles.a" rel="noopener" mdxType="Link">
  https://github.com/findyourexit
        </Link>
        <Divider mdxType="Divider" />
        <Text mdxType="Text">LinkedIn </Text>
        <Link href="https://www.linkedin.com/in/tom-larcher/" target="_blank" variant="styles.a" rel="noopener" mdxType="Link">
  https://linkedin.com/in/tom-larcher
        </Link>
      </Box>
      <Box mdxType="Box">
        <h2>{`topics`}</h2>
        <Divider mdxType="Divider" />
        <SourceTags mdxType="SourceTags">
  {tags => <Box mdxType="Box">
      {tags.sort((a, b) => b.percent - a.percent).map((tag, index) => {
              const {
                name,
                count,
                percent
              } = tag;
              return <Badge key={index} variant="primary" sx={{
                mr: 2,
                mb: 2,
                color: mix('secondary', 'primary', `${index / tags.length}`),
                borderColor: mix('secondary', 'primary', `${index / tags.length}`)
              }} mdxType="Badge">
              {name}
            </Badge>;
            })}
    </Box>}
        </SourceTags>
      </Box>
    </Grid>
    <Divider mdxType="Divider" />
    <Divider mdxType="Divider" />
    <Divider mdxType="Divider" />
    <h2>{`latest posts`}</h2>
    <Divider mdxType="Divider" />
    <SourceList filter="posts" mdxType="SourceList">
  {posts => <Grid sx={{
        gridTemplateColumns: ['1fr', '1fr', '1fr 1fr']
      }} mdxType="Grid">
      {posts.filter(edge => !edge.node.frontmatter.isPrivate).reduce((posts, post) => {
          return post.node.frontmatter.pinned ? [post, ...posts] : [...posts, post];
        }, []).splice(0, 4).map((edge, index) => {
          const {
            featuredImageUrl,
            frontmatter: {
              title,
              tags,
              date,
              dateModified,
              pinned
            },
            excerpt,
            fields: {
              slug
            }
          } = edge.node;
          return <PostCard key={index} title={title} featuredImageUrl={featuredImageUrl} tags={tags} date={date} dateModified={dateModified} excerpt={excerpt} slug={slug} pinned={pinned} mdxType="PostCard" />;
        })}
    </Grid>}
    </SourceList>
    <Divider mdxType="Divider" />
    <Flex sx={{
      justifyContent: 'center'
    }} mdxType="Flex">
  <Box mdxType="Box">
    <Button as={GatsbyLink} variant="ghost" to="/posts" mdxType="Button">
      More posts{' '}
      <Box as="span" role="img" aria-label="pencil" mdxType="Box">
        ✏️
      </Box>
    </Button>
  </Box>
    </Flex>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      